import './App.css';
import NavBar from './components/general/NavBar';
import Carousel from './components/general/Carousel';
import Products from './views/Products';
import Calculator from './views/Calculator';
import SuccessStories from './views/SuccessStories';
import Benefits from './views/Benefits';
import Footer from './views/Footer';
import Branches from './pages/Branches';
import { ProductContext } from './contexts/GeneralContexts';
import { Suspense, lazy, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress, Toolbar } from '@mui/material';
import ComponentRender from './pages/ComponentRender';
import Notification from './components/general/CookieNotification';
import DetailBlog from './pages/Blog/DetailBlog';
import ComiteRemuneracion from './pages/footer/ComiteRemuneracion';
import CostosComisiones from './pages/footer/CostosComisiones';
import DespachoCobranza from './pages/footer/DespachoCobranza';
import NewBuro from './pages/footer/NewBuro';
import TerminosConticiones from './pages/footer/TerminosConticiones';
import AvisoDePrivacidad from './pages/footer/AvisoDePrivacidad';
import AgreementForm from "./pages/Agreements/AgreementForm";
import Form from "./views/Form";



const Une = lazy(() => import('./pages/Une'));
const ProtectionFund = lazy(() => import('./pages/ProtectionFund'));
const FinancialReports = lazy(() => import('./pages/FinancialReports'));
const LegalNotice = lazy(() => import('./pages/AvisoLegal'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const Blog = lazy(() => import(`./pages/Blog/Blog`));

const cComiteRemuneracion = lazy(() => import('./pages/footer/ComiteRemuneracion'));
const cCostosComisiones = lazy(() => import('./pages/footer/CostosComisiones'));
const cDespachoCobranza = lazy(() => import('./pages/footer/DespachoCobranza'));

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme { }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#001933',
    },
    secondary: {
      main: '#db1d1f',
    },
    info: {
      main: '#23398F',
    },
    error: {
      main: '#FA9999',
    },
    success: {
      main: '#2F9324',
    },
    warning: {
      main: '#FFD88B',
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
  },
});

function App() {

  const [contextProd, setContexProd] = useState({});

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <NavBar></NavBar>
          <Toolbar />
          <ProductContext.Provider value={[contextProd, setContexProd]} >
            <Suspense fallback={
            <CircularProgress
              color="error"
              size="lg"
              value={55}
              sx={{m:30}}
            />
            }>
              <Routes>
                <Route path='/sucursales' element={<Branches />}></Route>
                <Route path='/nosotros' element={<AboutUs />}></Route>
                <Route path='/une/' element={<Une />} />
                <Route path='/fondo-de-proteccion/' element={<ProtectionFund />} />
                <Route path='/reportes-financieros/' element={<FinancialReports />} />
                <Route path='/aviso-legal/' element={<LegalNotice />} />
                <Route path="/documentacion" element={<ComponentRender></ComponentRender>}></Route>
                <Route path='/blog/' element={<Blog />} />
                <Route path="/blog/:formattedTitulo/:id/" element={<DetailBlog />} />
                <Route path="/convenio/:agreement/:agreementId/" element={<AgreementForm />} />
                <Route path='/comite-remuneracion/' element={<ComiteRemuneracion />} />
                <Route path='/terminos-condiciones/' element={<TerminosConticiones />} />
                <Route path='/costos-comisiones/' element={<CostosComisiones />} />
                <Route path='/aviso-privacidad/' element={<AvisoDePrivacidad />} />
                <Route path='/despacho-cobranza/' element={<DespachoCobranza />} />
                <Route path='/new-buro/' element={<NewBuro />} />
                <Route path="*" element={<Navigate to="/" replace />} />
                <Route path="/" element={
                  <>
                    <Notification/>
                    <Carousel/>
                    <Products/>
                    <Form/>
                    <SuccessStories/>
                    <Benefits/>
                  </>
                }></Route>
              </Routes>
            </Suspense>
            <Footer></Footer>
          </ProductContext.Provider>
        </BrowserRouter>
      </div >
    </ThemeProvider>
  );
}

export default App;
