import { Container,  Paper } from '@mui/material'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import {  useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CardsSkeleton from '../components/general/CardsSkeleton';
import { getComponent } from '../services/ComponentServices';

export default function SuccessStories() {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const [stories, setstories] = useState([])
    const [phrase, setphrase] = useState("")

    const getAllSuccessStories = async () => {
        const response = await getComponent(2, 2)
        const responsePhrase = await getComponent(5, 2)

        if (response.status === 200)
            setstories(response.data)

        if (responsePhrase.status === 200)
            setphrase(responsePhrase.data[0].text)
    }

    useEffect(() => {
        getAllSuccessStories()
    }, [])


    return (
        <Container sx={{ marginTop: 3 }}>
            <Typography variant="h4" color="#ff1719" fontWeight="bold">Casos de Éxito</Typography>
            <Divider sx={{ margin: 2 }}></Divider>

            <Carousel
                responsive={responsive}
                showDots={true}
                autoPlay
                autoPlaySpeed={3500}
                transitionDuration={500}
                keyBoardControl={true}
                infinite={true}
                removeArrowOnDeviceType="mobile">
                {
                    stories.length > 0 ? stories.map((s: any) => (
                        <Paper key={s.id} elevation={0} sx={{ ":hover": { transform: 'scale(1.2)', transition:'0.4s' } }}>
                            <img alt={s.id} src={s.image} height={350} width={"100%"} />
                        </Paper>
                    )) : <CardsSkeleton></CardsSkeleton>
                }

            </Carousel>

            <Typography variant="subtitle2" fontSize={30} color="#010130" margin={5}>{phrase}</Typography>
        </Container>
    )
}
